<template>
    <div>
        <div class="text-trade text-uppercase font-size-26 m-t-40 font-weight-900">{{ title }}</div>
        <div class="row align-items-start m-t-25">
            <div class="col-3 font-size-16 d-flex align-items-start p-t-8 p-b-5" v-for="(item, index) of labelList"
                 :key="index">
                <span class="font iconfont text-theme font-size-10 font-weight-900 m-r-5 m-t-3">&#xe69a;</span>
                <span>{{ item.name }}</span>
            </div>
        </div>
        <div class="split-line-gray m-t-40"></div>
    </div>
</template>

<script>

export default {
    name: 'faqs',
    data() {
        return {}
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: ''
        }
    },
    computed: {
        labelList() {
            let isCn = this.$i18n.locale === 'cn'
            let items = []
            this.items.forEach(item => {
                items.push({
                    name: isCn ? item.nameCn : item.nameEn
                })
            })
            return items
        }
    },
    methods: {}
}
</script>

<style scoped>

</style>