<template>
    <div>
        <section class="faqs">
            <div class="faqs-head d-flex justify-content-between align-items-end">
                <div class="faqs-title">{{ $t('faqs.faqs') }}</div>
                <div class="search-form" v-if="showSearch">
                    <input type="text" v-model="keyword" placeholder="Search" v-on:keyup.enter="handleSearch">
                    <div class="search-btn cursor" @click="handleSearch"><span class="icon iconfont">&#xe660;</span>
                    </div>
                </div>
            </div>
            <div class="accordion-group">
                <div class="accordion-card" :class="index === 0 ? 'border-top' : ''" v-for="(item, index) of faqs">
                    <div class="accordion-header cursor" @click="handleSpan(index)">
                        <div class="accordion-rn">{{ (index + 1)|rnFilter }}</div>
                        <div class="accordion-question">
                            <span class="faq-title">{{ item.title }}</span>
                        </div>
                        <div class="accordion-more">
                            <span v-if="faqSpans[index]">LESS -</span>
                            <span v-else>MORE +</span>
                        </div>
                    </div>
                    <div class="accordion-content" v-if="faqSpans[index]" v-html="item.content"></div>
                    <div class="underline"></div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import {getFaqsAPI} from '@/apis/common'

export default {
    name: 'faqs',
    filters: {
        rnFilter(rn) {
            return ('00' + rn).slice(-2);
        }
    },
    data() {
        return {
            faqSpans: [],
            faqList: [],
            keyword: null,
            keywordSearch: null
        }
    },
    props: {
        cityId: {
            type: Number,
            default: null
        },
        apartmentId: {
            type: Number,
            default: null,
        },
        bgColor: {
            type: String,
            default: 'section-white'
        },
        slug: {
            type: String,
            default: null,
        },
        showSearch: {
            type: Boolean,
            default: false
        }
    },
    created() {
        let params = {
            cityId: this.cityId,
            apartmentId: this.apartmentId,
            slug: this.slug,
        }
        getFaqsAPI(params).then(res => {
            if (res.data.code === 200) {
                this.faqList = res.data.data
            }
        })
    },
    computed: {
        faqs() {
            let isCn = this.$i18n.locale === 'cn'
            let faqs = []
            this.faqList.forEach(item => {
                faqs.push({
                    faqId: item.faqId,
                    title: isCn ? item.title : item.titleEn,
                    content: isCn ? item.content : item.contentEn
                })
            })
            for (let i = this.faqSpans.length; i < faqs.length; i++) {
                this.faqSpans.push(false);
            }
            let nameToMatch = this.keywordSearch
            if (!nameToMatch) return faqs
            return faqs.filter(item => {
                const itemName = item.title.replace(/\s/g, "").toLowerCase(); // 移除空格并转换为小写
                const name = nameToMatch.replace(/\s/g, "").toLowerCase();
                return itemName.includes(name);
            });
        }
    },
    mounted() {
    },
    methods: {
        handleSearch() {
            this.keywordSearch = this.keyword
        },
        handleSpan(index) {
            this.faqSpans[index] = !this.faqSpans[index];
            this.$forceUpdate();
        },
    },
}
</script>

<style scoped>
.swiper {
    position: relative;
    width: 100%;
    margin: 20px auto;
    height: 400px;
    overflow: hidden;
    border: 1px solid #ccc;
    box-sizing: border-box;
    user-select: none;
}

.swiperBody {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    white-space: nowrap;
    min-width: 100%;
}

.swiperBody .sitem {
    display: inline-block;
    height: 100%;
    background-position: center center;
    background-origin: padding-box;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.pointer {
    height: 6px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 20px;
    z-index: 2;
    text-align: center;
    font-size: 0;
}

.pointer .span {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
    margin: 0 10px;
    cursor: pointer;
}

.pointer .span.active {
    background-color: rgb(248, 123, 7);
}
</style>