<template>
    <div class="container-fluid p-0 m-0" v-if="apartmentData">

        <section class="feature-title">
            <div style="background-image: url(https://static.vitaalumnicn.com/static/vsweb/flat-bg.png);"
                 class="feature-title-background"></div>
        </section>
        <section class="section-white p-0">
            <div class="container head d-flex align-items-end justify-content-between">
                <div>
                    <div class="title">{{ apartmentData.name }}</div>
                    <div class="sub-title" style="margin-top: -5px">
                        {{ apartmentData.address }}
                    </div>
                </div>
            </div>
        </section>

        <section class="section-white text-trade text-uppercase font-size-16 font-weight-600 pt-0 p-b-35">
            <span class="text-city cursor" @click="$goto('/cities/' + slug)">{{ apartmentData.city }}</span> -
            <span> {{ apartmentData.name }} </span>
        </section>

        <section class="banner-img p-0" style="height: unset">
            <!--  todo -->
            <div v-show="false" class="text-center bg-black" style="font-size: 14px; line-height: 2.5">
                SUMMER OFFER: BOOK NOW AND RECEIVE £500 CASH WHEN YOU CHECK IN. EXCLUDING PROPERTY OF THE WEEK.
            </div>
            <div class="container-fluid row p-0 m-0">
                <div class="carousel-container p-0 position-relative">
                    <swiper class="swiper-container p-0"
                            :options="swiperOption"
                            ref="bannerImg"
                            style="width: 138vw !important;">
                        <swiper-slide style="width: 50% !important;" v-for="(banner, ind) in pcImgs" :key="ind">
                            <img class="img" :src="banner.thumbnail" alt=""/>
                        </swiper-slide>
                    </swiper>
                    <div class="scroll-btn justify-content-between top-50 bottom-50" style="width: 100%;">
                        <div class="btn-offset cursor icon iconfont" @click="prevSlide('bannerImg')"> &#xe64c;</div>
                        <div class="btn-offset cursor icon iconfont" @click="nextSlide('bannerImg')"> &#xe64d;</div>
                    </div>
                </div>
            </div>

            <div class="p-l-50 p-r-50 p-t-20 d-flex justify-content-end" v-if="apartmentData.pdfLink">
                <div class="link w-auto cursor" @click="$handleHref(apartmentData.pdfLink)">
                    <div class="join-us text-uppercase">Floorplan</div>
                    <div class="go-btn">
                        <span class="icon iconfont">&#xe60d;</span>
                    </div>
                </div>
            </div>
        </section>


        <section class="section-white p-t-20">
            <div class="container pt-0 m-t-10 m-b-10 p-l-50 p-r-50">
                <div class="row align-items-end">
                    <div class="col-4 ">
                        <div class="text-trade text-uppercase text-line font-size-42 font-weight-900" style="">
                            {{ apartmentData.name }}
                        </div>
                        <div class="text-trade sub-title font-size-16 font-weight-900">
                            {{ apartmentData.address }}
                        </div>
                    </div>
                    <div class="col-8 font-size-16 p-l-20 p-r-20" v-if="section1Data">
                        {{ section1Data.content }}
                    </div>
                </div>
                <div class="split-line-gray m-t-40"></div>

                <label-items
                    v-if="apartment && apartment.buildingFacilities && apartment.buildingFacilities.length > 0"
                    :title="$t('apartment.buildingWhatsIncluded')"
                    :items="apartment.buildingFacilities">
                </label-items>

                <label-items
                    v-if="apartment && apartment.roomFacilities && apartment.roomFacilities.length > 0"
                    :title="$t('apartment.standardFeatures')"
                    :items="apartment.roomFacilities">
                </label-items>
            </div>
        </section>


        <section class="room-types">
            <div class="common-header">
                <div class="common-title">
                    {{ $t('apartment.roomTypes') }}
                </div>
                <div class="common-note" v-if="section2Data" style="width: 450px">
                    {{ section2Data.content }}
                </div>
            </div>
            <div class="container pt-0" style="max-width: 1420px; padding: 0 50px"
                 v-if="roomTypes && roomTypes.length > 0">
                <div :class="index === 0 ? 'm-t-60' : 'm-t-120'" v-for="(roomType, index) of roomTypes"
                     :key="index">
                    <div class="room-type d-flex justify-content-center">
                        <div class="carousel-container" :class="index%2 === 0 ? 'me-auto' : 'ms-auto'">
                            <swiper :options="swiperRoomOption" ref="roomSlide">
                                <swiper-slide v-for="(image, ind) in roomType.pictures" :key="ind">
                                    <img class="img" :src="image.img" alt=""/>
                                </swiper-slide>
                            </swiper>
                            <div class="red-line-top" v-if="index%2 === 1"></div>
                            <div class="red-line-bottom" v-else></div>
                            <div class="arrow-btn" :class="index%2 === 0 ? 'pos-left_-20' : 'pos-right_-20'">
                                <div class="btn-offset cursor icon iconfont" @click="prevSlide('roomSlide', index)"> &#xe64c;</div>
                                <div class="btn-offset cursor icon iconfont" @click="nextSlide('roomSlide', index)"> &#xe64d;</div>
                            </div>
                        </div>


                        <div class="form-panel"
                             :class="index%2 === 0 ? 'position-right-0' : 'position-left-0'">
                            <div class="form-content">
                                <div class="form-title">{{ roomType.name }}</div>
                                <div class="form-note">{{ roomType.note }}</div>
                                <div class="form-note d-flex align-items-center justify-content-between">
                                    <div>{{ $t('common.size') }}</div>
                                    <div>{{ roomType.area }} {{ $t('common.sqm') }}</div>
                                </div>
                                <div class="border-bottom-gray"></div>
                                <div class="form-group m-t-15">
                                    <div class="form-label">{{ $t('common.name') }}</div>
                                    <input class="form-input" v-model="form.name" type="text">
                                </div>
                                <div class="form-group">
                                    <div class="form-label">{{ $t('common.phone') }}</div>
                                    <input class="form-input" v-model="form.cellphone" type="text">
                                </div>
                                <div class="form-group d-flex">
                                    <el-checkbox class="me-2 checked" v-model="checked"></el-checkbox>
                                    <div class="font-size-12">
                                        {{ $t('common.bySubmitting') }}
                                    </div>
                                </div>
                                <div class="submit-btn m-t-20" @click="handleSubmit">{{ $t('common.notifyMe') }}</div>
                                <div style="height: 20px"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="font-size-10 p-40 pb-0">
                    {{ $t('apartment.roomTypesDesc') }}
                </div>
            </div>
        </section>


        <section v-show="false" class="section-white vita-hero-banner">
            <div class="content position-relative">
                <div class="position-absolute panel-1"></div>
                <div class="position-absolute panel-2">
                    <img class="img" src="https://static.vitaalumnicn.com/static/vsweb/apartment.png"
                         alt="">
                </div>
                <div class="position-absolute panel-3 text-white">
                    <div class="title font-size-40 font-weight-700">
                        HAVE YOU FOUND YOUR HOME FOR SEPT 2023 YET?
                    </div>
                    <div class="font-size-16 font-weight-700 m-t-10">
                        Don’t miss your chance to experience the best.
                    </div>
                    <div class="font-size-10 m-t-10">
                        Talk to our team 8am-8pm 0203 890 1239.
                    </div>
                </div>
                <div class="position-absolute panel-4 cursor" @click="$goto('/contact-us')">
                    <div class="view-all-cities text-uppercase w-auto p-l-10 p-r-10 text-trade font-weight-900">book
                        today
                    </div>
                    <div class="go-btn"><span class="icon iconfont">&#xe60d;</span></div>
                </div>
            </div>
        </section>


        <!--    todo blog-->
        <section v-show="false" class="container-fluid vita-static-gallery-white">
            <div class="row">
                <div class="left-panel col-4" style="padding-top: 0">
                    <div class="panel-head sticky-top-0" style="display: block;padding-top: 30px">
                        <div class="content-title">
                            WHAT WE’RE ABOUT
                        </div>

                        <div class="left-content">
                            Vita Student is for students that want it all. For students that want to live without
                            limits.
                            <br><br>
                            With an all-inclusive service, living at Vita Student lifts some of the pressures from
                            university life, allowing you to focus on your stu dies and your experience.
                            <br><br>
                            Our buildings are full of friendly faces from many different places. All here to make the
                            most of their time at university. Whether you are creative or academic, Vita Student is the
                            place to learn, thrive and make connections.
                        </div>
                    </div>
                </div>
                <div class="col-panel col-4 p-0">
                    <div class="img-card sticky-top-0">
                        <div class="card-footer">
                            <div class="note">
                                As University Applications Soar
                            </div>
                            <div class="link-btn d-flex align-items-center justify-content-center">
                                <span class="font iconfont">&#xe60d;</span>
                            </div>
                        </div>
                        <img class="img img-center" decoding="async" loading="lazy"
                             src="https://www.vitastudent.com/wp-content/uploads/2023/02/Leeds-2-Room-128-1-scaled.jpg">
                    </div>
                </div>
                <div class="col-panel col-4 p-0">
                    <div class="img-card" v-for="index of 3" :key="index">
                        <div class="card-footer">
                            <div class="note">
                                As University Applications Soar, Student Accommodation Will Be More Scarce Than Ever
                            </div>
                            <div class="link-btn d-flex align-items-center justify-content-center">
                                <span class="font iconfont">&#xe60d;</span>
                            </div>
                        </div>
                        <img class="img-right" decoding="async" loading="lazy"
                             src="https://www.vitastudent.com/wp-content/uploads/2023/02/VITASTUDENT_PROFILES_NOV2021-243-scaled.jpg">
                    </div>
                </div>
            </div>
        </section>

        <section v-if="isLoading" style="margin-top: 220px">
            <Faqs :apartmentId="apartment.id" class="section-white"></Faqs>
        </section>

        <section class="banner-img vh-80">
            <img class="img"
                 src="https://www.vitastudent.com/wp-content/uploads/2023/02/05311_VITA_Oxford_Road_FM-40-1-scaled-e1678957798596.jpg?w=2048"
                 alt="">
        </section>

    </div>
</template>

<script>

import Faqs from '@/components/faqs'
import labelItems from '@/components/label-items'
import {getApartmentAPI, submitConsultsAPI} from '@/apis/common'

export default {
    name: "apartment",
    components: {
        Faqs, labelItems
    },
    data() {
        return {
            checked: true,
            form: {
                name: null,
                cellphone: null,
                source: 'pc_apartment',
                apartmentId: null
            },
            swiperOption: {
                pagination: false,
                autoplay: false,
                loop: true,
                slidesPerView: 2,
                spaceBetween: 5,
            },
            pcImgs: [],
            swiperRoomOption: {
                pagination: false,
                autoplay: false,
                loop: true
            },
            carousels: [
                {
                    id: 1, name: "aa", urls: [
                        'https://www.vitastudent.com/wp-content/uploads/2022/12/BCN-Deluxe-405-RT-scaled.jpg',
                        'https://www.vitastudent.com/wp-content/uploads/2022/12/BCN-Deluxe-405-RT-scaled.jpg',
                        'https://www.vitastudent.com/wp-content/uploads/2022/12/BCN-Deluxe-405-RT-scaled.jpg',
                        'https://www.vitastudent.com/wp-content/uploads/2022/12/BCN-Deluxe-405-RT-scaled.jpg'
                    ]
                },
                {
                    id: 2, name: "bb", urls: [
                        'https://www.vitastudent.com/wp-content/uploads/2022/12/BCN-Deluxe-405-RT-scaled.jpg',
                        'https://www.vitastudent.com/wp-content/uploads/2022/12/BCN-Deluxe-405-RT-scaled.jpg',
                        'https://www.vitastudent.com/wp-content/uploads/2022/12/BCN-Deluxe-405-RT-scaled.jpg',
                        'https://www.vitastudent.com/wp-content/uploads/2022/12/BCN-Deluxe-405-RT-scaled.jpg'
                    ]
                },
                {
                    id: 3, name: "cc", urls: [
                        'https://www.vitastudent.com/wp-content/uploads/2022/12/BCN-Deluxe-405-RT-scaled.jpg',
                        'https://www.vitastudent.com/wp-content/uploads/2022/12/BCN-Deluxe-405-RT-scaled.jpg',
                        'https://www.vitastudent.com/wp-content/uploads/2022/12/BCN-Deluxe-405-RT-scaled.jpg',
                        'https://www.vitastudent.com/wp-content/uploads/2022/12/BCN-Deluxe-405-RT-scaled.jpg'
                    ]
                }
            ],
            apartment: null,
            isLoading: false,
            city: null,
            slug: null
        }
    },
    created() {
        this.slug = this.$route.params.slug
        this.initData(this.$route.params.slug, this.$route.params.apartmentSlug);
    },
    computed: {
        roomTypes() {
            let isCn = this.$i18n.locale === 'cn' ? true : false
            let roomTypes = []
            if (this.apartment && this.apartment.roomTypes.length > 0) {
                this.apartment.roomTypes.forEach(roomType => {
                    roomTypes.push({
                        name: roomType.name,
                        area: roomType.area,
                        note: isCn ? roomType.note : roomType.noteEn,
                        pictures: roomType.pictures,
                        url: roomType.url,
                        roomTypeId: roomType.id
                    })
                })
            }
            return roomTypes
        },
        apartmentData() {
            let isCn = this.$i18n.locale === 'cn' ? true : false
            if (this.apartment === null) return null
            let apartmentData = this.apartment
            return {
                address: apartmentData.address,
                name: isCn ? apartmentData.cnName : apartmentData.name,
                city: isCn ? apartmentData.city : apartmentData.cityEnName,
                pdfLink: apartmentData.pdfLink,
            }
        },
        section1Data() {
            let lang = this.$i18n.locale === 'en' ? 2 : 1
            if (!this.apartment) return null
            return this.apartment.sections.find(obj => obj.lang === lang && obj.type === 7);
        },
        section2Data() {
            let lang = this.$i18n.locale === 'en' ? 2 : 1
            if (!this.apartment) return null
            return this.apartment.sections.find(obj => obj.lang === lang && obj.type === 8);
        }
    },
    methods: {
        handleSubmit() {
            if(this.checked === false) {
                this.$message({
                    type: 'warning',
                    message: this.$t('common.agreenChecked')
                })
                return
            }
            let form = this.form
            form.apartmentId = this.apartment.apartmentId
            form.cityId = this.apartment.cityId
            if(!(form.name && form.cellphone)) {
                this.$message({
                    type: 'warning',
                    message: this.$t('common.errorNotFillInfo')
                })
                return
            }
            submitConsultsAPI(form).then(res => {
                if (res.data.code === 200) {
                    this.$message({
                        type: 'success',
                        message: this.$t('common.successfully')
                    })
                }
            })
        },
        handleSpeakToUs(index) {
            const formArea = document.getElementById("formArea_" + index);
            formArea.style.transition = "transform 0.3s ease";
            formArea.style.transform = "translateY(-100%)";
            formArea.style.bottom = "0";
        },
        closeSpeakToUs(index) {
            const formArea = document.getElementById("formArea_" + index);
            formArea.style.transition = "transform 0.3s ease";
            formArea.style.transform = "translateY(0)";
            formArea.style.bottom = "-100%";
        },
        initData(slug, apartmentSlug) {
            this.isLoading = false
            getApartmentAPI(slug, apartmentSlug).then(res => {
                if (res.data.code === 200) {
                    this.apartment = res.data.data
                    this.pcImgs = this.apartment.pcImgs
                    this.isLoading = true
                    this.$initMeta(this.apartment.pageTitle ? this.apartment.pageTitle : null,
                        this.apartment.pageKeywords ? this.apartment.pageKeywords : null,
                        this.apartment.pageDesc ? this.apartment.pageDesc : null)
                }
            })
        },
        prevSlide(swiper, index = null) {
            if (index != null) this.$refs[swiper][index].swiper.slidePrev();
            else this.$refs[swiper].swiper.slidePrev();
        },
        nextSlide(swiper, index = null) {
            if (index != null) this.$refs[swiper][index].swiper.slideNext();
            else this.$refs[swiper].swiper.slideNext();
        }
    }
}
</script>

<style lang="less" scoped>


@import "./../city.less";
@import "apartment.less";


</style>
